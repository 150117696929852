'use strict';

Gri.module({
  name: 'tab-alpha',
  ieVersion: null,
  $el: $('.tab-alpha'),
  container: '.tab-alpha',
  fn: function () {
    
  }
});
